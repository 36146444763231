/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItem} from './AsideMenuItem'
import {can} from '../../../../app/setups/constant'
import {Fragment} from 'react'

export function AsideMenuMain() {
  const intl = useIntl()
  const auth = useAuth()
  const user = auth.currentUser

  return (
    <>
      <div className='menu-item'>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/general/gen025.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        />
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Main Menu</span>
        </div>
      </div>
      <AsideMenuItem
        to='live-transaction'
        icon='/media/icons/duotune/general/gen022.svg'
        title={intl.formatMessage({id: 'MENU.LIVE_TRANSACTION'})}
      />
      <AsideMenuItem
        to='employee'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Employee'
      />
      <AsideMenuItem
        to='fingerprint'
        icon='/media/icons/duotune/abstract/abs023.svg'
        title='Fingerprint'
      />
      <AsideMenuItem
        to='attendance'
        icon='/media/icons/duotune/abstract/abs014.svg'
        title='Attendance'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Histories</span>
        </div>
      </div>
      <AsideMenuItem
        to='sync-history'
        icon='/media/icons/duotune/communication/com001.svg'
        title='Sync History'
      />
      <AsideMenuItem
        to='job-history'
        icon='/media/icons/duotune/abstract/abs015.svg'
        title='Job History'
      />

      {can('View Users', user) ? (
        <Fragment>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
            </div>
          </div>
          <AsideMenuItem
            to='users'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Users'
          />
        </Fragment>
      ) : (
        ''
      )}
      {can('View Masters', user) ? (
        <Fragment>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Master</span>
            </div>
          </div>
          <AsideMenuItem
            to='master/machines'
            icon='/media/icons/duotune/electronics/elc002.svg'
            title={intl.formatMessage({id: 'MENU.MACHINES'})}
          />
        </Fragment>
      ) : (
        ''
      )}
    </>
  )
}
