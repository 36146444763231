import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const LiveTransaction = lazy(() => import('../pages/live-transaction/LiveTransaction'))
  const Employee = lazy(() => import('../pages/employee'))
  const SyncHistory = lazy(() => import('../pages/sync-history'))
  const FingerPrint = lazy(() => import('../pages/fingerprint'))
  const Attendance = lazy(() => import('../pages/attendance'))
  const JobHistory = lazy(() => import('../pages/job-history'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const MUsersPage = lazy(() => import('../pages/users'))
  const Account = lazy(() => import('../pages/users/Account'))
  const MMachines = lazy(() => import('../pages/master/machines'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Dashboard />} />
        {/* Lazy Modules */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='live-transaction/*'
          element={
            <SuspensedView>
              <LiveTransaction />
            </SuspensedView>
          }
        />
        <Route
          path='employee/*'
          element={
            <SuspensedView>
              <Employee />
            </SuspensedView>
          }
        />
        <Route
          path='attendance/*'
          element={
            <SuspensedView>
              <Attendance />
            </SuspensedView>
          }
        />
        <Route
          path='job-history/*'
          element={
            <SuspensedView>
              <JobHistory />
            </SuspensedView>
          }
        />
        <Route
          path='fingerprint/*'
          element={
            <SuspensedView>
              <FingerPrint />
            </SuspensedView>
          }
        />
        <Route
          path='sync-history/*'
          element={
            <SuspensedView>
              <SyncHistory />
            </SuspensedView>
          }
        />
        <Route
          path='users'
          element={
            <SuspensedView>
              <MUsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='account'
          element={
            <SuspensedView>
              <Account />
            </SuspensedView>
          }
        />
        {/* Masters */}
        <Route
          path='master/machines'
          element={
            <SuspensedView>
              <MMachines />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
