import axios from 'axios'
import React, {Fragment, useEffect, useState} from 'react'
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Popover,
  ListGroup,
  Modal,
} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import {PageTitle} from '../../../_metronic/layout/core'
import MachineModel from '../../models/MachineModel'
import {errCatch} from '../../setups/constant'
import 'react-datepicker/dist/react-datepicker.min.css'
import moment from 'moment'
import 'moment/locale/id'
import Swal from 'sweetalert2'

export default function Dashboard() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  const [mesins, setMesins] = useState<MachineModel[]>([])
  const [autoRefreshVal, setAutoRefreshVal] = useState<number>(1)
  const [selectedMsn, setSelectedMsn] = useState<{sn: string; nama_mesin: string}[]>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [restartModal, setRestartModal] = useState(false)
  const [resLoading, setResLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [hapusModal, setHapusModal] = useState(false)
  const [hapusFotoModal, setHapusFotoModal] = useState(false)
  const [hapusFotoLoading, setHapusFotoLoading] = useState(false)
  const [schedule, setSchedule] = useState<Date>()
  const closeResMod = () => {
    setRestartModal(false)
    setSchedule(undefined)
  }
  const closeHapMod = () => {
    setHapusModal(false)
    setSchedule(undefined)
  }
  const closeHapFotMod = () => {
    setHapusFotoModal(false)
    setSchedule(undefined)
  }
  const submitRestart = () => {
    setResLoading(true)
    axios
      .post('/mesin-absen/dashboard/restart-mesin', {
        msns: selectedMsn,
        schedule: moment(schedule).format('YYYY-MM-DD HH:mm:ss'),
      })
      .finally(() => setResLoading(false))
      .then(() => {
        closeResMod()
        Swal.fire('Success', 'Restart dijadwalkan', 'success')
      })
      .catch((e) => errCatch(e))
  }
  const submitHapus = () => {
    setDelLoading(true)
    axios
      .post('/mesin-absen/dashboard/hapus-presensi', {
        msns: selectedMsn,
        schedule: moment(schedule).format('YYYY-MM-DD HH:mm:ss'),
      })
      .finally(() => setDelLoading(false))
      .then(() => {
        closeHapMod()
        Swal.fire('Success', 'Penghapusan data presensi dijadwalkan', 'success')
      })
      .catch((e) => errCatch(e))
  }
  const submitHapusFoto = () => {
    setHapusFotoLoading(true)
    axios
      .post('/mesin-absen/dashboard/hapus-foto-presensi', {
        msns: selectedMsn,
        schedule: moment(schedule).format('YYYY-MM-DD HH:mm:ss'),
      })
      .finally(() => setHapusFotoLoading(false))
      .then(() => {
        closeHapFotMod()
        Swal.fire('Success', 'Penghapusan foto presensi dijadwalkan', 'success')
      })
      .catch((e) => errCatch(e))
  }
  useEffect(() => {
    const intr = setInterval(() => {
      setIsLoading(true)
      axios
        .get('/mesin-absen/dashboard')
        .finally(() => setIsLoading(false))
        .then((d: {data: MachineModel[]}) => setMesins(d.data))
        .catch((e) => errCatch(e))
    }, autoRefreshVal * 60000)
    setIsLoading(true)
    axios
      .get('/mesin-absen/dashboard')
      .finally(() => setIsLoading(false))
      .then((d: {data: MachineModel[]}) => setMesins(d.data))
      .catch((e) => errCatch(e))
    return () => clearInterval(intr)
  }, [count, autoRefreshVal])

  return (
    <div>
      <PageTitle>DASHBOARD</PageTitle>
      <Row>
        <Col className='mb-3'>
          <Card>
            <Card.Body>
              <Card.Title>Action</Card.Title>
              <Row>
                <Col>
                  <Button variant='primary me-1' onClick={() => setCount(count + 1)}>
                    <i className={'fas fa-circle-notch ' + (isLoading ? 'fa-spin' : '')}></i>{' '}
                    Refresh
                  </Button>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 150, hide: 200}}
                    overlay={
                      <Popover title='Selected:' id={'pop-selct'}>
                        <ListGroup>
                          {selectedMsn.map((va, ia) => (
                            <ListGroup.Item key={ia}>
                              {ia + 1}. {va.nama_mesin}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Popover>
                    }
                  >
                    <Button
                      variant='primary me-1'
                      onClick={() => {
                        setSchedule(new Date())
                        setRestartModal(true)
                      }}
                      disabled={!selectedMsn.length}
                    >
                      <i className={'fas fa-sync ' + (isLoading ? 'fa-spin' : '')}></i> Restart
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 150, hide: 200}}
                    overlay={
                      <Popover title='Selected:' id={'pop-selct'}>
                        <ListGroup>
                          {selectedMsn.map((va, ia) => (
                            <ListGroup.Item key={ia}>
                              {ia + 1}. {va.nama_mesin}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Popover>
                    }
                  >
                    <Button
                      variant='danger me-1'
                      onClick={() => {
                        setSchedule(new Date())
                        setHapusModal(true)
                      }}
                      disabled={!selectedMsn.length}
                    >
                      <i className={'fas fa-x ' + (isLoading ? 'fa-spin' : '')}></i> Hapus Data
                      Presensi
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 150, hide: 200}}
                    overlay={
                      <Popover title='Selected:' id={'pop-selct'}>
                        <ListGroup>
                          {selectedMsn.map((va, ia) => (
                            <ListGroup.Item key={ia}>
                              {ia + 1}. {va.nama_mesin}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Popover>
                    }
                  >
                    <Button
                      variant='danger me-1'
                      onClick={() => {
                        setSchedule(new Date())
                        setHapusFotoModal(true)
                      }}
                      disabled={!selectedMsn.length}
                    >
                      <i className={'fas fa-image-portrait ' + (isLoading ? 'fa-spin' : '')}></i>{' '}
                      Hapus Foto Presensi
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <Form.Label>Auto Refresh</Form.Label>
                  <Form.Select
                    defaultValue={autoRefreshVal}
                    onChange={(e) => setAutoRefreshVal(parseFloat(e.target.value))}
                    size='sm'
                  >
                    <option value={10 / 60}>10 Detik</option>
                    <option value={0.5}>30 Detik</option>
                    <option value={1}>1 Menit</option>
                    <option value={5}>5 Menit</option>
                  </Form.Select>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <Fragment>
            <Col className='mb-3 d-flex justify-content-center' sm={12}>
              <i className='fas fa-circle-notch fa-spin text-primary fs-5x'></i>
            </Col>
            <Col className='mb-3 d-flex justify-content-center' sm={12}>
              <span className='display-6 text-muted'>LOADING...</span>
            </Col>
          </Fragment>
        ) : (
          mesins.map((v, i) => (
            <Fragment key={i}>
              <Col className='mb-3' md={4} sm={12}>
                <Card className={'border border-' + (v.online ? 'success' : 'danger')}>
                  <Card.Body>
                    <Card.Title>
                      <Form.Check
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedMsn([...selectedMsn, {sn: v.sn, nama_mesin: v.nama_mesin}])
                          } else {
                            setSelectedMsn([...selectedMsn].filter((x) => x.sn !== v.sn))
                          }
                        }}
                        inline
                        label={v.nama_mesin}
                        disabled={!v.online}
                        id={'chck' + i}
                        checked={!!selectedMsn.find((x) => x.sn === v.sn)}
                      />
                    </Card.Title>
                    <Card.Subtitle className='text-muted'>{v.sn}</Card.Subtitle>
                    <Badge bg={v.online ? 'success' : 'danger'}>
                      <i className='fas fa-network-wired text-white me-1'></i>{' '}
                      {v.online ? 'ONLINE' : 'OFFLINE'}
                    </Badge>
                    <table className='table table-striped my-3 gs-0 gx-1 gy-2'>
                      <tbody>
                        <tr>
                          <td>Timezone</td>
                          <td>{v.timezone}</td>
                        </tr>
                        <tr>
                          <td>User Terdaftar</td>
                          <td>{v.enrolled_users}</td>
                        </tr>
                        <tr>
                          <td>Fingerprint Terdaftar</td>
                          <td>{v.enrolled_fingers}</td>
                        </tr>
                        <tr>
                          <td>Jumlah Presensi</td>
                          <td>{v.att_records}</td>
                        </tr>
                        <tr>
                          <td>IP Address</td>
                          <td>{v.ip_address}</td>
                        </tr>
                        <tr>
                          <td>Record not sent</td>
                          <td>{v.not_sent}</td>
                        </tr>
                      </tbody>
                    </table>
                    Latest Record:
                    <p className='card-text'>
                      [{v.latest_record?.type?.description}]{' '}
                      {v.latest_record?.check_time.toLocaleString()} {v.latest_record?.pin}{' '}
                      {v.latest_record?.emp?.name}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Fragment>
          ))
        )}
      </Row>
      <Modal centered show={restartModal} onHide={closeResMod}>
        <Modal.Header closeButton>
          <Modal.Title>Restart Mesin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Pilh Waktu</Form.Label>
          <ReactDatePicker
            showTimeSelect
            className='form-control'
            selected={schedule}
            onChange={(e) => (e ? setSchedule(e) : '')}
            dateFormat='dd/MM/yyyy HH:mm'
          />
          <b className='mt-3'>
            {moment(schedule).diff(moment(), 'minute') < 1
              ? 'Sekarang'
              : moment(schedule).fromNow()}
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' disabled={resLoading || !schedule} onClick={submitRestart}>
            Jadwalkan Restart
          </Button>
          <Button variant='secondary' onClick={closeResMod}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={hapusModal} onHide={closeHapMod}>
        <Modal.Header closeButton>
          <Modal.Title>Hapus data presensi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Pilh Waktu</Form.Label>
          <ReactDatePicker
            showTimeSelect
            className='form-control'
            selected={schedule}
            onChange={(e) => (e ? setSchedule(e) : '')}
            dateFormat='dd/MM/yyyy HH:mm'
          />
          <b className='mt-3'>
            {moment(schedule).diff(moment(), 'minute') < 1
              ? 'Sekarang'
              : moment(schedule).fromNow()}
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' disabled={delLoading || !schedule} onClick={submitHapus}>
            Jadwalkan Penghapusan
          </Button>
          <Button variant='secondary' onClick={closeHapMod}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={hapusFotoModal} onHide={closeHapFotMod}>
        <Modal.Header closeButton>
          <Modal.Title>Hapus foto presensi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Pilh Waktu</Form.Label>
          <ReactDatePicker
            showTimeSelect
            className='form-control'
            selected={schedule}
            onChange={(e) => (e ? setSchedule(e) : '')}
            dateFormat='dd/MM/yyyy HH:mm'
          />
          <b className='mt-3'>
            {moment(schedule).diff(moment(), 'minute') < 1
              ? 'Sekarang'
              : moment(schedule).fromNow()}
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            disabled={hapusFotoLoading || !schedule}
            onClick={submitHapusFoto}
          >
            Jadwalkan Penghapusan
          </Button>
          <Button variant='secondary' onClick={closeHapFotMod}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
